.App {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
  height: 75px;
  /* width: 50px; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-body {
  margin: auto;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.App-header {
  background-color: #d52b1e;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0px 1%;
  position: fixed;
  width: 98%;
  z-index: 20;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-chatwidget-container {
  width: 50vw;
  height: 90vh;
  margin-top: 10%;
  position: relative;
}

.App-chatwidget {
  height: 85vh;
}

.App-col {
  display: flex;
  flex-direction: column;
}

.App-row {
  display: flex;
  flex-direction: row;
  width: 50%;
}

.App-align-center {
  align-items: center;
}

@media screen and (max-width: 500px) {
  .App-chatwidget-container {
    width: 100vw;
  }

  .App-chatwidget {
    height: 80vh;
  }

  .ac-container {
    width: 65%;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .App-chatwidget-container {
    width: 100vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .App-chatwidget-container {
    width: 100vw;
  }

  .ac-container {
    width: 70%;
  }
}

.App-light-heading {
  font-weight: normal;
}

.skip-link {
  background: #e77e23;
  left: 50%;
  height: 1em;
  padding: 8px;
  position: absolute;
  transform: translateY(-200%);
  transition: transform 0.3s;
}

.skip-link:focus {
  transform: translateY(0%);
}