.auto-complete-sendbox-default {
  display: flex;
  align-items: center;
}

.auto-complete-sendbox {
  padding: 10px;
  display: flex;
  align-items: center;
  flex: 10000 1;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
  margin: 10px;
}

.auto-complete-sendbox:focus-within {
  border: 2px solid #d52b20;
  outline: none;
}

.auto-complete-sendbox-input {
  background-color: White;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  width: 95%;
  outline: 0;
  padding: 0;
  flex: 1;
}

.auto-complete-container {
  position: relative;
  width: 100%;
  top: 8%;
}

.auto-complete-sendbox-overlay {
  position: fixed;
  top: 3em;
  width: 50vw;
  height: 90vh;
  z-index: 10;
  background-color: white;
  margin: auto;
}

.auto-complete-suggestions-container > ul {
  list-style: none;
  text-align: left;
  margin: 10px;
  padding: 0px;
  height: 80vh;
  overflow-y: auto;
}

.auto-complete-suggestions {
  cursor: pointer;
  color: #d52b1e;
  padding: 10px;
  border-radius: 0.25rem;
}

.auto-complete-suggestions:hover {
  color: white;
  background-color: #d52b1e;
  font-weight: bolder;
}

.auto-complete-active-suggestion {
  cursor: pointer;
  color: white;
  background-color: #d52b1e;
  font-weight: bolder;
  display: block;
  padding: 10px;
  border-radius: 0.25em;
}

.auto-complete-send-btn {
  padding: 5px;
  margin: 5px;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  justify-content: center;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  background: white;
  border: 2px solid #d52b20;
}

.auto-complete-send-btn:hover {
  background: #d52b20;
  color: white;
}

.auto-complete-send-btn:focus {
  background: #d52b20;
  color: white;
}

.auto-complete-info {
  margin-left: 10px;
  padding-left: 10px;
  color: #615151;
}

@media screen and (max-width: 500px) {
  .auto-complete-sendbox-overlay {
    width: 100vw;
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .auto-complete-sendbox-overlay {
    width: 100vw;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .auto-complete-sendbox-overlay {
    width: 100vw;
  }
}
