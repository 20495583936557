.minimizable-web-chat > button.maximize {
  background-color: #39c;
  border-radius: 50%;
  border: 2px solid #39c;
  bottom: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  color: White;
  font-size: 30px;
  height: 64px;
  outline: 0;
  padding: 15px;
  position: fixed;
  right: 20px;
  width: 64px;
}

.minimizable-web-chat > button.maximize > .red-dot {
  color: #ef0000;
  font-size: 14px;
  position: absolute;
  right: 12px;
  top: 12px;
}

.minimizable-web-chat > button.maximize:focus:not(:active),
.minimizable-web-chat > button.maximize:hover:not(:active) {
  background-color: #069;
  border-color: #069;
}

.minimizable-web-chat > button.maximize:active {
  background-color: White;
  color: #39c;
}

.minimizable-web-chat > .chat-box {
  background-color: rgba(255, 255, 255, 0.8);
  /* border-radius: 4px; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border: 4px solid #39c;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 320px;
  position: fixed;
  top: 20px;
  width: 30%;
}

.minimizable-web-chat > .chat-box.hide {
  display: none;
}

.minimizable-web-chat > .chat-box.left {
  left: 20px;
}

.minimizable-web-chat > .chat-box.right {
  right: 20px;
}

.minimizable-web-chat > .chat-box > header {
  background-color: #39c;
  height: 40px;
  display: flex;
}

.minimizable-web-chat > .chat-box > header > .filler {
  flex: 0 10000 100%;
}

.minimizable-web-chat > .chat-box > header > button {
  width: 40px;
  height: 40px;
  background-color: Transparent;
  border: 0;
  color: White;
  outline: 0;
}

.minimizable-web-chat > .chat-box > header > button:focus:not(:active),
.minimizable-web-chat > .chat-box > header > button:hover:not(:active) {
  color: rgba(255, 255, 255, 0.6);
}

.minimizable-web-chat > .chat-box > header > button:focus:active {
  color: rgba(255, 255, 255, 0.4);
}

.minimizable-web-chat > .chat-box > .react-web-chat {
  flex: 1;
}

img[role="link"] {
  cursor: pointer;
}
