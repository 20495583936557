.web-chat {
  overflow: hidden;
  position: relative;
}

.react-web-chat {
  height: 80%;
}

.connect-spinner {
  display: flex;
  margin: auto;
  text-align: center;
}

.connect-spinner > .content {
  margin: auto;
}

.connect-spinner > .content > .icon {
  font-size: 64px;
  margin: auto;
  width: 50%;
}
