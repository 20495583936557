.suggested-actions__button {
  padding: 5px;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  justify-content: center;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  width: 100%;
  background: white;
  border: 2px solid #d52b20;
  font-size: inherit;
}

.suggested-actions__button:focus {
  /* outline: none; */
  background-color: #d52b20;
  color: white;
}

.suggested-actions__button:focus {
  outline: none;
}

.suggested-actions__button:hover {
  cursor: pointer;
  background-color: #d52b20;
  color: white;
}

.suggested-actions__carousel {
  padding-bottom: 5px;
  padding-top: 5px;
}
/* 
.suggested-actions__carousel .react-film__filmstrip {
  scrollbar-width: "none";
} */

.suggested-actions__carousel .react-film__filmstrip .react-film__filmstrip__item {
  margin-left: 5px;
  margin-right: 5px;
}

.suggested-actions__carousel .react-film__filmstrip .react-film__filmstrip__item:first-child {
  padding-left: 5px;
}

.suggested-actions__carousel .react-film__filmstrip .react-film__filmstrip__item:last-child {
  padding-right: 5px;
}
